import React from "react";

import LinkedInPNG from "../assets/linkedin.png";
import TwitterPNG from "../assets/twitter.png";

function Contact() {
  return (
    <section
      className="w-full py-12 bg-orange-500 text-coolGray-50"
      id="contact"
    >
      <div className="grid w-full grid-cols-1 gap-6 px-6 lg:px-32 sm:grid-cols-2">
        <div className="pr-4 border-white lg:border-r-2">
          <h1 className="text-3xl font-bold leading-none text-center lg:text-5xl sm:text-left">
            Nous contacter
          </h1>
          <div className="flex flex-row justify-center w-full gap-4 mt-4 lg:justify-start">
            <a href="https://www.linkedin.com/company/kernel-biomedical/">
              <img
                src={LinkedInPNG}
                alt="linkedin logo"
                className="w-12 h-12"
              />
            </a>
            <a href="https://twitter.com/KerNelBiomed">
              <img src={TwitterPNG} alt="twitter logo" className="w-12 h-12" />
            </a>
          </div>
        </div>

        <div className="self-center space-y-4">
          <p className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-8 h-8 mr-2 lg:w-5 lg:h-5 sm:mr-6"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>18 rue Marie Curie, 76000 Rouen, France</span>
          </p>

          <p className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6 mr-2 lg:w-5 lg:h-5 sm:mr-6"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
            <span>
              <a
                className="hover:underline"
                href="mailto:contact@kernelbiomedical.com"
              >
                contact@kernelbiomedical.com
              </a>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Contact;
