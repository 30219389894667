import React, { useState } from "react";
import { Link } from "gatsby";

import Logo from "../assets/logo_bold.svg";
import icon from "../images/icon.png";

function Navbar() {
  const [isActive, setIsActive] = useState(false);

  const toggleNav = () => {
    setIsActive(isActive ? false : true);
  };

  return (
    <header className="p-4 bg-coolGray-100 text-coolGray-800">
      <div className="container flex justify-between h-16 mx-auto">
        <Link
          to="/"
          aria-label="Back to homepage"
          className="flex items-center p-2"
        >
          <img src={icon} alt="icon" className="w-20 mr-4" />
          <Logo className="w-40 h-40" />
        </Link>
        <ul className="items-stretch hidden w-auto space-x-3 md:flex">
          <li className="flex">
            <Link
              to="/recherche"
              className="flex items-center -mb-0.5 border-b-2 px-4 border-transparent"
              activeClassName="border-orange-500 text-orange-500"
            >
              Laboratoire d'essais
            </Link>
          </li>
          <li className="flex">
            <Link
              to="/services"
              className="flex items-center -mb-0.5 border-b-2 px-4 border-transparent"
              activeClassName="border-orange-500 text-orange-500"
            >
              Nos autres services
            </Link>
          </li>
          <li className="flex">
            <Link
              to="http://papers.kernelbiomedical.com"
              className="flex items-center -mb-0.5 border-b-2 px-4 border-transparent"
              activeClassName="border-orange-500 text-orange-500"
            >
              Publications
            </Link>
          </li>

          <li className="flex">
            <Link
              to="/about-us"
              className="flex items-center -mb-0.5 border-b-2 px-4 border-transparent"
              activeClassName="border-orange-500 text-orange-500"
            >
              Entreprise
            </Link>
          </li>
        </ul>
        <button onClick={toggleNav} className="flex justify-end p-4 md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {isActive && (
        <div className="sm:hidden">
          <div class="px-2 pt-2 pb-3 space-y-1 bg-orange-500 rounded-lg mt-2 shadow-lg">
            <Link
              to="/recherche"
              className="block w-full px-3 py-2 text-base font-medium rounded-md text-coolGray-100 hover:bg-orange-100 hover:text-coolGray-800"
              activeClassName="bg-orange-100 text-coolGray-800"
            >
              Recherche
            </Link>

            <Link
              to="/services"
              className="block w-full px-3 py-2 text-base font-medium rounded-md text-coolGray-100 hover:bg-orange-100 hover:text-coolGray-800"
              activeClassName="bg-orange-100 text-coolGray-800"
            >
              Services
            </Link>

            <Link
              to="/produits"
              className="block px-3 py-2 text-base font-medium rounded-md text-coolGray-100 hover:bg-orange-100 hover:text-coolGray-800"
              activeClassName="bg-orange-100 text-coolGray-800"
            >
              Produits
            </Link>

            <Link
              to="/about-us"
              className="block px-3 py-2 text-base font-medium rounded-md text-coolGray-100 hover:bg-orange-100 hover:text-coolGray-800"
              activeClassName="bg-orange-100 text-coolGray-800"
            >
              Qui sommes-nous
            </Link>
          </div>
        </div>
      )}
    </header>
  );
}

export default Navbar;
