import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Contact from "./Contact";

function Layout(props) {
  return (
    <>
      <Helmet>
        <title>{props.title} | KerNel Biomedical</title>
      </Helmet>
      <Navbar />
      <div className="bg-coolGray-100">{props.children}</div>
      <Contact />
      <Footer />
    </>
  );
}

export default Layout;
