import React from "react";

function Footer() {
  return (
    <footer className="p-6 text-coolGray-800 bg-coolGray-100">
      <div className="flex items-center justify-center px-6 pt-12 text-sm">
        <span className="text-coolGray-600">© KerNel Biomedical 2023</span>
      </div>
    </footer>
  );
}

export default Footer;
